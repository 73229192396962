import * as React from 'react';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import iconClose from '../../assets/images/close-button.svg';
import { AccessLevel } from '../../common/enums';
import { AutomationIds } from '../../common/enums/AutomationElements.enum';
import { UserViewSettings } from '../../common/enums/UserViewSettings.enum';
import * as dv from '../../common/interfaces';
import { UserAnalyticsGlobalContext } from '../../common/metrics/UserAnalyticsGlobalContext';
import { OptionRepository } from '../../common/utils/OptionRepository';
import { SymbolLookup } from '../../common/utils/ViewSource';
import { Grid } from '../../components/Grid';
import RSidePanel from '../../components/RSidePanel';
import { AsyncResultDone } from '../../store';
import { Actions } from '../App/Actions';
import { iframeStatusSelector } from '../App/Selectors';
import { isViewPlanOrUserEligible } from '../Auth/Selectors';
import * as ViewActions from './Actions';
import Description from './Description';
import { DismissErrors } from './DismissErrors/DismissErrors';
import FilterContainer from './Filter/FilterContainer';
import * as ReportActions from './Report/Actions';
import ToggleFormats from './ToggleFormats';
import ToggleWrapText from './ToggleWrapText';
import { VerticalRule } from './VerticalRule';
import './View.css';
import ViewHeader from './ViewHeader';
import { ViewStatus } from './ViewStatus/ViewStatus';

interface ViewContainerProps {
    viewConfig: AsyncResultDone<dv.ViewWithOwnerAndUserDetails>;
}

const ViewContainer = ({ viewConfig }: ViewContainerProps) => {
    const dispatch = useDispatch();
    const [showViewDescription, setShowViewDescription] = useState(false);
    const [wrapText, setWrapText] = useState(false);
    const [showFormats, setShowFormats] = useState(false);
    useEffect(() => {
        // Add view details to user analytics
        UserAnalyticsGlobalContext.addViewData(viewConfig.data);
        dispatch(ViewActions.Actions.fetchEligibility(viewConfig.data.id));
        // This removes the loading spinner. This is necessary because the loading spinner is shown when the view is first loaded.
        dispatch(Actions.resetAppStage());

        return () => {
            // Reset the view report state
            dispatch(ReportActions.Actions.resetReportState());

            // Remove view details from user analytics
            UserAnalyticsGlobalContext.removeViewData();
        };
    }, [dispatch, viewConfig.data]);

    const inIframe = useSelector(iframeStatusSelector);
    const isLicensedUser = useSelector(isViewPlanOrUserEligible);
    const currentUserAccessLevel = viewConfig.data.currentUserDetails.accessLevel ?? AccessLevel.NONE;

    const handleClickViewInfo = (): void => {
        setShowViewDescription(true);
    };

    const handleCloseDetailsPanel = (): void => {
        // This scrollTo readjusts display on mobile devices
        window.scrollTo(0, 0);
        setShowViewDescription(false);
    };

    const handleWrapTextSelect = (e: SyntheticEvent): void => {
        const toggledWrapText = !wrapText;
        OptionRepository.setOption(viewConfig.data.id, UserViewSettings.WRAP_TEXT, toggledWrapText);

        setWrapText(toggledWrapText);
    };

    const handleShowFormatsSelect = (e: SyntheticEvent): void => {
        const toggledShowFormats = !showFormats;
        OptionRepository.setOption(viewConfig.data.id, UserViewSettings.SHOW_FORMATS, toggledShowFormats);

        setShowFormats(toggledShowFormats);
    };

    return (
        <div className="grid-container">
            <div className="grid-view-header">
                <ViewHeader viewName={viewConfig.data.name} viewId={viewConfig.data.id} onClickViewInfo={handleClickViewInfo} />
            </div>
            <div className="grid-view-sub-header">
                <div className="left-header-items">
                    <div className="left-header-items">
                        {/* Adding filter button with dummy values and keeping it disabled until we enable true filtering i.e,*/}
                        {/* filtering on server side instead of client side*/}
                        <FilterContainer
                            fields={[]}
                            filters={[]}
                            filterModalOpen={false}
                            activeFilterIndex={0}
                            editFilterIndex={0}
                            filterDisabled={true}
                            totalRows={0}
                            filteredRowCount={0}
                            onClickFilterIcon={() => {}}
                            onClickFilterMenu={() => {}}
                            onCancelFilter={() => {}}
                            onDeleteFilter={(): Promise<boolean> => {
                                return Promise.resolve(false);
                            }}
                            onApplyFilter={(): Promise<boolean> => {
                                return Promise.resolve(false);
                            }}
                            onFilterModalClose={() => {}}
                            picklistSymbolImageMap={new Map<string, SymbolLookup | undefined>()}
                            allFilterItemsMap={new Map<number, Map<string, any>>()}
                            filterMenuOpen={false}
                            onNewFilter={() => {}}
                            onFilterOff={() => {}}
                            onFilterSelect={() => {}}
                            onFilterEdit={() => {}}
                            onFilterDuplicate={() => {}}
                            isNewFilter={false}
                            isDuplicateFilter={false}
                            currentUserAccessLevel={AccessLevel.NONE}
                            isLoadingData={false}
                            isVisibleLoadingIndicator={false}
                            onClickFilterButton={() => {}}
                        />
                        <VerticalRule />
                        <ToggleFormats onClick={handleShowFormatsSelect} showFormats={showFormats} />
                        <VerticalRule />
                        <ToggleWrapText onClick={handleWrapTextSelect} wrapText={wrapText} />
                        <ViewStatus />
                        <DismissErrors />
                    </div>
                </div>
            </div>
            <div className="grid-view-all">
                <div className="grid-view-body">
                    <Grid viewId={viewConfig.data.id} wrapText={wrapText} showFormats={showFormats} disableSetFocus={false} />
                </div>
                <RSidePanel
                    controlId={AutomationIds.VIEW_SIDE_PANEL}
                    inIframe={inIframe}
                    sidePanelOpened={showViewDescription}
                    onClick={handleCloseDetailsPanel}
                >
                    <button data-client-id={AutomationIds.VIEW_CLOSE} className={'close-button'} onClick={() => handleCloseDetailsPanel()}>
                        <img src={iconClose} alt={'icon-close'} className={'icon-close'} />
                    </button>
                    <Description
                        viewDescription={viewConfig.data.description ?? ''}
                        viewOwner={viewConfig.data.ownerDetails}
                        viewId={viewConfig.data.id}
                        isLicensedUser={isLicensedUser}
                        currentUserAccessLevel={currentUserAccessLevel}
                        dataClientId={AutomationIds.DETAILS_TAB_DESCRIPTION}
                    />
                </RSidePanel>
            </div>
        </div>
    );
};

export default ViewContainer;
